import React from "react";
import s from "./style.module.css";
import { ReactComponent as Left } from "./back.svg";
import { ReactComponent as Down } from "./down.svg";
import { ReactComponent as Up } from "./up.svg";
import { ReactComponent as Right } from "./next.svg";

const Arrow = ({ dir, bg }) => {
  return (
    <div className={s.wrap} style={{ "--icon-bg": bg }}>
      {dir === "left" && <Left />}
      {dir === "down" && <Down />}
      {dir === "up" && <Up />}
      {dir === "right" && <Right />}
    </div>
  );
};

export default Arrow;
